import React from 'react';
import styled from 'styled-components';
import buttonDecoration from '../style/assets/shapes/button-graphic.png';
import heroImage from '../style/assets/img/hero-image.png';

const HeroContainer = styled.section`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #DBDCCD;
  padding-bottom: 3rem;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    padding: 3rem 0;
  }
`;

const HeroContent = styled.div`
  flex: 1;
  padding-left: 3rem;

  @media (max-width: 768px) {
    padding: 0;
    margin-bottom: 2rem;
  }
`;

const Title = styled.h1`
  font-family: 'Poppins', Arial, sans-serif;
  font-weight: bold;
  font-size: 4.2rem;
  margin-bottom: 1.5rem;
  line-height: 4.2rem;
  color: #382E2E;

  @media (max-width: 768px) {
    font-size: 2rem;
    line-height: 2rem;
    margin-bottom: 1rem;
  }
`;

const Text = styled.p`
  font-family: 'Poppins', Arial, sans-serif;
  font-weight: 600;
  font-size: 1.25rem;
  margin-bottom: 2.5rem;
  line-height: 1.2rem;
  color: #382E2E;

  @media (max-width: 768px) {
    margin-bottom: 2rem;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 3rem;
  margin-left: 3rem;
  width: 15rem;
  height: 9rem;
  background-image: url(${buttonDecoration});
  background-repeat: no-repeat;
  background-position: top right;
  display: flex;
  align-items: flex-end;

  @media (max-width: 768px) {
    margin: 0;
    width: 100%;
    height: 9rem;
    justify-content: center;
  }
`;

const Button = styled.a`
  padding: 1rem 2rem;
  font-family: 'Poppins', Arial, sans-serif;
  font-weight: bold;
  font-size: 2.25rem;
  background-color: #202A31;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  color: #DBDCCD;
  width: auto;
  height: auto;
  -webkit-box-shadow: 5px 1px 20px -5px rgba(0,0,0,0.5); 
  box-shadow: 5px 1px 20px -5px rgba(0,0,0,0.5);
  text-decoration: none;

  &:hover {
    background-color: black;
  }

  @media (max-width: 768px) {
    width: auto;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  padding-right: 3rem;

  @media (max-width: 768px) {
    padding: 0;
    order: -1;
    margin-bottom: 2rem;
    justify-content: center;
  }
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
  margin-bottom: 1.5rem;
  padding: 2rem 5rem 0 0;

  @media (max-width: 768px) {
    padding: 0;
    max-width: 60%;
  }
`;

const HeroSection = () => {
  return (
    <HeroContainer>
      <HeroContent>
        <Title>Die "etwas andere"<br />Grafikagentur</Title>
        <Text>Das Grafikdesign ABO für jeden.<br />Pausieren oder künden jederzeit</Text>
        <ButtonWrapper>
          <Button href="#preise">Abos</Button>
        </ButtonWrapper>
      </HeroContent>
      <ImageContainer>
        <Image src={heroImage} alt="" />
      </ImageContainer>
    </HeroContainer>
  );
};

export default HeroSection;
