import React from 'react';
import Header from './Header';
import HeroSection from './Hero';
import PitchSection from './PitchSection';
import ProcessSection from './ProcessSection';
import OnboardingSection from './OnboardingSection';
import ServicesSection from './ServiceSection';
import PricingSection from './PricingSection';
import FAQSection from './FAQSection';
import Footer from './Footer';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Content = styled.div`
  max-width: 100%;
  margin: 0 auto;
  position: relative;
`;

const Sections = () => {
  return (
    <Wrapper>
      <Content>
        <Header />
        <HeroSection />
        <PitchSection />
        <ProcessSection />
        <OnboardingSection />
        <ServicesSection />
        <PricingSection />
        <FAQSection />
        <Footer />
      </Content>
    </Wrapper>
  );
};

export default Sections;
