import React from 'react';
import styled from 'styled-components';
import shape from '../style/assets/shapes/shape-bg.png';
import email from '../style/assets/icons/email.png';
import time from '../style/assets/icons/time-left.png';
import infinity from '../style/assets/icons/infinity.png';
import quoteOpen from '../style/assets/icons/quotation-mark-open.png';
import quoteShape from '../style/assets/shapes/quote-shape.png';

const ProcessContainer = styled.section`
  padding: 5rem 1.25rem;
  text-align: center;
  background-color: #BBC8CE;
  color: black;
  background-image: url(${shape});
  background-repeat: no-repeat;
  background-position: bottom left;
`;

const Title = styled.h2`
  font-family: 'Poppins', Arial, sans-serif;
  font-weight: bold;
  font-size: 3.2rem;
  line-height: 3.2rem;
  color: #382E2E;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 2rem;
    line-height: 2rem;
  }
`;

const IconCardContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin: 2rem 0;
`;

const IconCard = styled.div`
  width: 25rem;
  padding: 1.5rem;
  margin: 1rem;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const CardIcon = styled.img`
  max-width: 100%;
  height: auto;
`;

const CardTitle = styled.h3`
  font-size: 1.25rem;
  margin-bottom: 10px;
  color: #382E2E;
`;

const QuotationWrapper = styled.div`
  position: relative;
  margin: 3rem auto;
  width: 40rem;
  height: 12rem;
  display: flex;
  align-items: flex-end;

  @media (max-width: 768px) {
    width: 80%;
  }
`;

const ShapeBottomRight = styled.div`
  position: absolute;
  bottom: -5rem;
  right: -4rem;
  width: 6rem;
  height: 6rem;
  background-image: url(${quoteShape});
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 2;
  transform: rotate(-15deg); 
`;

const QuoteIconTopLeft = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  background-image: url(${quoteOpen});
  background-repeat: no-repeat;
  background-size: contain;
`;

const QuoteIconBottomRight = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50px;
  height: 50px;
  background-image: url(${quoteOpen});
  background-repeat: no-repeat;
  background-size: contain;
  transform: rotate(180deg);
`;

const Quotation = styled.blockquote`
  font-family: 'Roc Grotesk', Arial, sans-serif;  
  font-size: 1.25rem;
  font-weight: bold;
  background-color: #C9E6EE;
  padding: 3rem 2rem;
  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 2rem 1rem;
  }
`;

const ProcessSection = () => {
  return (
    <ProcessContainer id="leistungen">
      <Title>Wir haben das Rad nicht<br />neu erfunden, nur das Design!</Title>
      <IconCardContainer>
        <IconCard>
          <CardIcon src={email} alt="" />
          <CardTitle>Erstelle ein Abo und wir erledigen so viele Anfragen wie du möchtest.</CardTitle>
        </IconCard>
        <IconCard>
          <CardIcon src={time} alt="" />
          <CardTitle>Erhalte deine fertiggestellten Designs in kurzer Zeit.</CardTitle>
        </IconCard>
        <IconCard>
          <CardIcon src={infinity} alt="" />
          <CardTitle>Wir ändern deine Designs bis du 100% zufrieden bist.</CardTitle>
        </IconCard>
      </IconCardContainer>
      <QuotationWrapper>
        <Quotation>
          <QuoteIconTopLeft />
          <QuoteIconBottomRight />
          Ich muss keinen Grafiker mehr anstellen, Graflow erledigt alles für mich.
        </Quotation>
        <ShapeBottomRight />
      </QuotationWrapper>
    </ProcessContainer>
  );
};

export default ProcessSection;
