import React from 'react';
import Sections from './components/Sections';
import Navigation from './components/Navigation';
import styled, { createGlobalStyle } from 'styled-components';

import RocGroteskRegular from './style/assets/fonts/Rocgrotesk-Regular.otf';
import RocGroteskBold from './style/assets/fonts/Rocgrotesk-Bold.otf';

const Wrapper = styled.div`
  font-family: Arial, sans-serif;
  color: #333;
  display: flex;
  justify-content: center;
`;

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Roc Grotesk';
    src: url(${RocGroteskRegular}) format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Roc Grotesk';
    src: url(${RocGroteskBold}) format('opentype');
    font-weight: bold;
    font-style: normal;
  }

  @import url('https://fonts.googleapis.com/css2?family=Baskervville&family=Josefin+Sans&family=Poppins:wght@600&display=swap');
`;

function App() {
  return (
    <Wrapper>
      <GlobalStyle />
      <Navigation />
      <Sections />
    </Wrapper>
  );
}

export default App;
