import React from 'react';
import styled from 'styled-components';
import heart from '../style/assets/shapes/heart.png';
import decoration from '../style/assets/shapes/button-graphic.png';
import arrow from '../style/assets/shapes/right-drawn-arrow.png'
import centerImg from '../style/assets/img/ebook-mockup.png'

const ServicesContainer = styled.section`
  padding: 5rem 1.25rem;
  text-align: center;
  background-color: #C9E6EE;
  position: relative;
`;

const ShapeTopLeft = styled.div`
  position: absolute;
  top: 2rem;
  left: 3rem;
  width: 9rem;
  height: 9rem;
  background-image: url(${heart});
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 12;
  transform: rotate(-15deg); 

  @media (max-width: 768px) {
    top: -2rem;
  }
`;

const ShapeTopRight = styled.div`
  position: absolute;
  top: 2rem;
  right: 3rem;
  width: 6rem;
  height: 6rem;
  background-image: url(${decoration});
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 12;
  transform: rotate(270deg);

  @media (max-width: 768px) {
    display: none;
  }
`;

const Title = styled.h2`
  font-family: 'Poppins', Arial, sans-serif;
  font-weight: bold;
  font-size: 3.2rem;
  margin-bottom: 3.5rem;
  line-height: 3.2rem;
  color: #382E2E;

  @media (max-width: 768px) {
    font-size: 2rem;
    line-height: 2rem;
  }
`;

const CenterButton = styled.a` 
  padding: 1rem 2rem;
  position: relative;
  font-family: 'Poppins', Arial, sans-serif;
  font-weight: bold;
  font-size: 2.25rem;
  background-color: #202A31;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  color: #DBDCCD;
  width: 12rem;
  height: 5rem;
  -webkit-box-shadow: 5px 1px 20px -5px rgba(0,0,0,0.5); 
  box-shadow: 5px 1px 20px -5px rgba(0,0,0,0.5);
  text-decoration: none;

  &:hover {
    background-color: black;
  }
`;

const ButtonTopRight = styled.div`
  position: absolute;
  top: -5rem;
  right: -13rem;
  width: 12rem;
  height: 12rem;
  background-image: url(${arrow});
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 12;
  transform: rotate(45deg); 
`;

const ThreeColumnLayout = styled.div`
  margin: 6rem auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    margin: 6rem 0 0 0;
  }
`;

const ImageContainer = styled.div`
  grid-column: 2 / span 1;
  text-align: center;
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
`;

const ListContainer = styled.div`
  display: flex;
  justify-content: center;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    li {
      font-family: 'Poppins', Arial, sans-serif;
      font-weight: bold;
      font-size: 1.25rem;
      margin-bottom: 1.5rem;
      line-height: 4.2rem;
      color: #382E2E;
      text-shadow: 2px 1px 5px rgba(0, 0, 0, 0.3);

      @media (max-width: 768px) {
        line-height: 1.25rem;
      }
    }

    #rightList:last-child {
      color: #6E6E6B;
    }
  }
`;

const serviceList = [
  "Logos",
  "Flyers",
  "UI Design",
  "Werbeplakate",
  "Icons",
  "Verpackungen",
  "Brand Guides",
  "Visitenkarten",
  "Blog Grafiken",
  "Digitale Werbung",
  "Broschüren",
  "Branding",
  "Socialmedia Design",
  "und mehr...",
];

const ServicesSection = () => {
  const firstList = serviceList.slice(0, 7);
  const secondList = serviceList.slice(7);

  return (
    <ServicesContainer>
      <ShapeTopLeft />
      <ShapeTopRight />
      <Title>Alles was dein Unternehmen braucht:</Title>
      <CenterButton href="#preise">
        Abos
        <ButtonTopRight />
      </CenterButton>
      <ThreeColumnLayout>
        <ListContainer>
          <ul>
            {firstList.map((service, index) => (
              <li key={index}>{service}</li>
            ))}
          </ul>
        </ListContainer>
        <ImageContainer>
          <Image src={centerImg} alt="" />
        </ImageContainer>
        <ListContainer>
          <ul>
            {secondList.map((service, index) => (
              <li id="rightList" key={index}>{service}</li>
            ))}
          </ul>
        </ListContainer>
      </ThreeColumnLayout>
    </ServicesContainer>
  );
};

export default ServicesSection;
