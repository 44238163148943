import React from 'react';
import styled from 'styled-components';
import circle from '../style/assets/shapes/faq-shape.png';
import arrow from '../style/assets/shapes/faq-arrow.png';
import stripes from '../style/assets/shapes/faq-stripes.png'
import chat from '../style/assets/shapes/faq-chat-shape.png'

const FAQContainer = styled.section`
  padding: 5rem 1.25rem;
  text-align: center;
  background-color: #BBC8CE;
  position: relative;
`;

const ShapeTopLeft = styled.div`
  position: absolute;
  top: 2rem;
  left: 0;
  width: 12rem;
  height: 12rem;
  background-image: url(${circle});
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 12;
`;

const ShapeBottomLeft = styled.div`
  position: absolute;
  bottom: 2rem;
  left: -3rem;
  width: 9rem;
  height: 9rem;
  background-image: url(${stripes});
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 12;
`;

const ShapeBottomRight = styled.div`
  position: absolute;
  bottom: 2rem;
  right: 0;
  width: 9rem;
  height: 9rem;
  background-image: url(${chat});
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 12;
`;

const ShapeTopRight = styled.div`
  position: absolute;
  top: 2rem;
  right: 0;
  width: 12rem;
  height: 12rem;
  background-image: url(${arrow});
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 12;

  @media (max-width: 768px) {
    display: none;
  }
`;

const Title = styled.h2`
  font-family: 'Poppins', Arial, sans-serif;
  font-weight: bold;
  font-size: 3.2rem;
  margin-bottom: 1.5rem;
  line-height: 4.2rem;
  color: #382E2E;

  @media (max-width: 768px) {
    font-size: 2rem;
    line-height: 2rem;
  }
`;

const BoxesContainer = styled.div`
  margin: 3rem auto;
  width: 70%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Box = styled.div`
  width: calc(45% - 20px);
  padding: 1.5rem;
  background-color: #FFDFF0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const QuestionTitle = styled.h3`
  font-family: 'Roc Grotesk', Arial, sans-serif;  
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.5rem;
  color: #382E2E;

  span  {
    font-family: 'Poppins', Arial, sans-serif;
  }
`;

const AnswerText = styled.p`
  font-family: 'Poppins',Arial,sans-serif;
  font-size: 0.75rem;
  color: #382E2E;
`;

const FAQSection = () => {
  return (
    <FAQContainer id="faq">
      <ShapeTopLeft />
      <ShapeTopRight />
      <Title>FAQs</Title>
      <BoxesContainer>
        <Box>
          <QuestionTitle>Warum sollte ich nicht einfach einen Grafiker einstellen?</QuestionTitle>
          <AnswerText>
            Gute Frage! Zunächst einmal belaufen sich die jährlichen Kosten für einen vollzeitbeschäftigten Senior-Designer inzwischen auf über 80.000 Schweizer Franken, zuzüglich Sozialleistungen (und viel Glück bei der Suche nach einem solchen). Abgesehen davon haben Sie möglicherweise nicht immer genug Arbeit, um sie ständig zu beschäftigen, sodass Sie nicht für die Zeit bezahlen müssen, die Sie nicht nutzen können.<br /><br />
            Mit dem Monatsplan können Sie Ihr Abonnement so oft pausieren und wieder aufnehmen, wie Sie möchten, um sicherzustellen, dass Sie Ihren Designer nur dann bezahlen, wenn für ihn Arbeiten verfügbar sind.
          </AnswerText>
        </Box>
        <Box>
          <QuestionTitle>Wie schnell erhalte ich meineDesign<span>'</span>s?</QuestionTitle>
          <AnswerText>
            Im Durchschnitt werden die meisten Anfragen in nur zwei Tagen oder weniger bearbeitet. Komplexere Anfragen können jedoch länger dauern.
          </AnswerText>
          <QuestionTitle>Was ist wenn ich mit einem Design nicht zufrieden bin?</QuestionTitle>
          <AnswerText>
            Keine Sorge! Wir werden das Design so lange überarbeiten, bis Sie zu 100 % zufrieden sind.
          </AnswerText>
        </Box>
        <Box>
          <QuestionTitle>Wie funktioniert das Pausieren des Abos?</QuestionTitle>
          <AnswerText>
            Wir verstehen, dass Sie möglicherweise nicht genug Designarbeit haben, um den ganzen Monat damit zu füllen. Vielleicht haben Sie im Moment nur ein oder zwei Designwünsche. Dann ist es praktisch, Ihr Abonnement zu pausieren.
            <br /><br />
            Die Abrechnungszyklen basieren auf einem Zeitraum von 31 Tagen. Angenommen, Sie melden sich an, nutzen den Dienst 21 Tage lang und beschließen dann, Ihr Abonnement zu pausieren. Das bedeutet, dass der Abrechnungszeitraum angehalten wird und Ihnen der Service noch 10 Tage lang zur Verfügung steht, den Sie jederzeit in der Zukunft nutzen können.
          </AnswerText>
        </Box>
        <Box>
          <QuestionTitle>Gibt es Anfragen die wir nicht annehmen?</QuestionTitle>
          <AnswerText>
            Absolut. Graflow deckt die folgenden Designarbeiten nicht ab: 3D-Modellierung, animierte Grafiken (GIFS usw.), Dokumentendesign (medizinische Formulare usw.), komplexe Verpackungen und umfangreiches Druckdesign (Zeitschriften, Bücher usw.).
          </AnswerText>
        </Box>
      </BoxesContainer>
      <ShapeBottomLeft />
      <ShapeBottomRight />
    </FAQContainer>
  );
};

export default FAQSection;
