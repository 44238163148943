import React from 'react';
import styled from 'styled-components';
import blackLines from '../style/assets/shapes/shape-black-lines.png';
import cardDecoration from '../style/assets/shapes/button-graphic.png';
import cardShape from '../style/assets/shapes/quote-shape.png';
import calendar from '../style/assets/icons/calendar.png'

const PricingContainer = styled.section`
  padding: 5rem 0;
  text-align: center;
  background-color: #FFDFF0;
  position: relative;
`;

const ShapeTopRight = styled.div`
  position: absolute;
  top: -8rem;
  right: 0;
  width: 20rem;
  height: 25rem;
  background-image: url(${blackLines});
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 12;

  @media (max-width: 768px) {
    display: none;
  }
`;

const Title = styled.h2`
  font-family: 'Poppins', Arial, sans-serif;
  font-weight: bold;
  font-size: 3.2rem;
  margin-bottom: 1.5rem;
  line-height: 4.2rem;
  color: #382E2E;

  @media (max-width: 768px) {
    font-size: 2rem;
    line-height: 2rem;
  }
`;

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;
  width: 70%;
  margin: 0 auto;

  #extra-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    // grid-template-columns: 1fr;
    width: 90%;

    #desc1 {
      order: -1;
    }

    #desc2 {
      display: none;
    }
  }
`;

const CardShapeLeft = styled.div`
  position: absolute;
  top: 9rem;
  left: 10rem;
  width: 7rem;
  height: 5rem;
  background-image: url(${cardDecoration});
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 12;
  transform: rotate(270deg); 

  @media (max-width: 768px) {
    display: none;
  }
`;

const CardShapeRight = styled.div`
  position: absolute;
  bottom: 1rem;
  right: 4rem;
  width: 7rem;
  height: 5rem;
  background-image: url(${cardShape});
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 12;
  transform: rotate(-15deg);
  
  @media (max-width: 768px) {
    display: none;
  }
`;

const Card = styled.div`
  width: 20rem;
  padding: 1rem;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;

  span {
    font-weight: bold;
    color: #707070;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  #cta-button {
    margin-top: 4rem;
    font-size: 1.25rem;
    text-align: center;
  }

  @media (max-width: 768px) {
    width: auto;
  }
`;

const Icon = styled.img`
  margin: 0.5rem auto;
  width: 7rem;
  height: 7rem;
`;

const CardTitle = styled.h3`
  font-family: 'Roc Grotesk', Arial, sans-serif;
  font-size: 2rem;
  margin-bottom: 10px;
`;

const CardText = styled.p`
  font-family: 'Poppins', Arial, sans-serif;
  font-size: 1rem;
`;

const Price = styled.p`
  font-family: 'Poppins', Arial, sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
`;

const Button = styled.a`
  padding: 0.5rem 2rem;
  font-family: 'Poppins', Arial, sans-serif;
  font-weight: bold;
  font-size: 1.5rem;
  background-color: #202A31;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  color: #DBDCCD;
  width: 15rem;
  height: auto;
  -webkit-box-shadow: 5px 1px 20px -5px rgba(0,0,0,0.5); 
  box-shadow: 5px 1px 20px -5px rgba(0,0,0,0.5);
  text-decoration: none;

  &:hover {
    background-color: black;
  }
`;

const PricingSection = () => {
  return (
    <PricingContainer id="preise">
      <ShapeTopRight />
      <Title>Unsere Abos</Title>
      <CardsContainer>
        <CardShapeLeft />
        <Card>
          <CardTitle>Standart</CardTitle>
          <CardText>Eine Designanfrage auf einmal. Pausieren oder künden jederzeit.</CardText>
          <span>- OHNE UI Design</span>
          <Price>CHF 2499.-/m</Price>
          <Button href="https://buy.stripe.com/28odTY8fU5ws97G5kk" target="_blank" rel="noreferrer">Los geht's!</Button>
        </Card>
        <Card>
          <CardTitle>Pro</CardTitle>
          <CardText>Eine Designanfrage auf einmal. Pausieren oder künden jederzeit.</CardText>
          <span>- UI Design inbegriffen</span>
          <Price>CHF 3299.-/m</Price>
          <Button href="https://buy.stripe.com/fZeaHM53If72gA86op" target="_blank" rel="noreferrer">Los geht's!</Button>
        </Card>
        <Card id="extra-card" style={{ gridRow: 'span 2' }}>
          <Icon src={calendar} />
          <CardTitle>Kontaktiere uns.</CardTitle>
          <CardText>Erfahre mehr über Graflow.</CardText>
          <Button id="cta-button" href="https://calendly.com/hello-graflow/30min" target="_blank" rel="noreferrer">Termin vereinbaren</Button>
        </Card>
        <Card id="desc1">
          <CardTitle>Inbegriffen</CardTitle>
          <ul>
            <li>- Eine Anfrage auf einmal</li>
            <li>- Durchschnittliche Lieferzeit von 48 Stunden</li>
            <li>- Unbegrenzte Marken</li>
            <li>- Unbegrenzte Benutzer</li>
            <li>- Unbegrenzte Stockfotos über Shutterstock</li>
            <li>- Einfache Zahlung über Kreditkarte</li>
            <li>- Pausieren oder Künden jederzeit</li>
          </ul>
        </Card>
        <Card id="desc2">
          <CardTitle>Inbegriffen</CardTitle>
          <ul>
            <li>- Eine Anfrage auf einmal</li>
            <li>- Durchschnittliche Lieferzeit von 48 Stunden</li>
            <li>- Unbegrenzte Marken</li>
            <li>- Unbegrenzte Benutzer</li>
            <li>- Unbegrenzte Stockfotos über Shutterstock</li>
            <li>- Einfache Zahlung über Kreditkarte</li>
            <li>- Pausieren oder Künden jederzeit</li>
          </ul>
        </Card>
        <CardShapeRight />
      </CardsContainer>
    </PricingContainer>
  );
};

export default PricingSection;
