import React from 'react';
import styled from 'styled-components';
import infinity from '../style/assets/icons/infinity.png';
import flash from '../style/assets/icons/flash.png';
import refund from '../style/assets/icons/refund.png';
import design from '../style/assets/icons/design.png';
import doubleArrow from '../style/assets/icons/double-arrow.png';
import uniqueness from '../style/assets/icons/uniqueness.png';

const OnboardingContainer = styled.section`
  padding: 5rem 1.25rem;
  text-align: center;
  background-color: #DBDCCD;
  position: relative;
`;

const Title = styled.h2`
  font-family: 'Poppins', Arial, sans-serif;
  font-weight: bold;
  font-size: 3.2rem;
  margin-bottom: 1.5rem;
  line-height: 4.2rem;
  color: #382E2E;

  @media (max-width: 768px) {
    font-size: 2rem;
    line-height: 2rem;
  }
`;

const Subtitle = styled.p`
  font-family: 'Poppins', Arial, sans-serif;
  font-weight: 600;
  font-size: 1.25rem;
  margin-bottom: 2.5rem;
  line-height: 1.2rem;
  color: #382E2E;
`;

const CenterButton = styled.a`
  padding: 1rem 2rem;
  font-family: 'Poppins', Arial, sans-serif;
  font-weight: bold;
  font-size: 2.25rem;
  background-color: #202A31;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  color: #DBDCCD;
  width: 12rem;
  height: 5rem;
  -webkit-box-shadow: 5px 1px 20px -5px rgba(0,0,0,0.5); 
  box-shadow: 5px 1px 20px -5px rgba(0,0,0,0.5);
  text-decoration: none;

  &:hover {
    background-color: black;
  }
`;

const IconCardContainer = styled.div`
  margin: 3rem auto;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
`;

const IconCard = styled.div`
  width: 20rem;
  padding: 1.25rem;
  margin: 1.25rem;
`;

const CardIcon = styled.img`
  max-width: 100%;
  width: 3rem;
  height: 3rem;
  height: auto;
`;

const CardTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #382E2E;
`;

const CardText = styled.p`
  font-family: 'Poppins', Arial, sans-serif;
  font-size: 1rem;
  color: #382E2E;
`;

const OnboardingSection = () => {
  return (
    <OnboardingContainer id="vorteile">
      <Title>Deine Vorteile</Title>
      <Subtitle>Qualität, die so gut ist, dass du nirgendwo anders hingehen musst für dein Design.</Subtitle>
      <CenterButton href="#preise">Abos</CenterButton>
      <IconCardContainer>
        <IconCard>
          <CardIcon src={infinity} />
          <CardTitle>Designboard</CardTitle>
          <CardText>Füge so viele Design Anfragen zu deinem Board hinzu, soviel du möchtest.</CardText>
        </IconCard>
        <IconCard>
          <CardIcon src={flash} />
          <CardTitle>Schnelle Lieferung</CardTitle>
          <CardText>Erhalte deine Designs (nacheinander) in nur wenigen Tagen.</CardText>
        </IconCard>
        <IconCard>
          <CardIcon src={refund} />
          <CardTitle>Fixe monatliche Rate</CardTitle>
          <CardText>Keine Überraschungen! Zahle immer den gleichen Preis.</CardText>
        </IconCard>
        <IconCard>
          <CardIcon src={design} />
          <CardTitle>Top Qualität</CardTitle>
          <CardText>Top Qualität, schnell und wann immer du sie brauchst.</CardText>
        </IconCard>
        <IconCard>
          <CardIcon src={doubleArrow} />
          <CardTitle>Flexibel</CardTitle>
          <CardText>Änderungen jederzeit möglich.</CardText>
        </IconCard>
        <IconCard>
          <CardIcon src={uniqueness} />
          <CardTitle>Einzigartig</CardTitle>
          <CardText>Jedes Design ist perfekt auf dich abgestimmt.</CardText>
        </IconCard>
      </IconCardContainer>
    </OnboardingContainer>
  );
};

export default OnboardingSection;
