import React from 'react';
import styled from 'styled-components';
import buttonArrow from '../style/assets/icons/arrow-up.png';

const Navbar = styled.nav`
  background-color: #202A31;
  color: #DBDCCD;
  border: 2px solid #707070;
  border-radius: 1.5rem;
  padding: 0 1.5rem;
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 2rem;
  width: 40%;
  z-index: 1000;

  @media (max-width: 768px) {
    display: none;
  }
`;

const NavMenu = styled.ul`
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0;
`;

const NavItem = styled.li`
  margin-right: 1.25rem;
  font-family: 'Roc Grotesk', Arial, sans-serif;
  font-weight: bold;
  font-size: 1.25rem;

  &:last-child {
    margin-right: 0;
  }

  a {
    color: #fff;
    text-decoration: none;
  }
`;

const NavButton = styled.button`
  width: 5rem;
  height: 2.25rem;
  padding: 0.75rem 1rem;
  background-image: url(${buttonArrow});
  background-repeat: no-repeat;
  background-position: center;
  background-color: #DBDCCD;
  border: 2px solid #707070;
  border-radius: 0.75rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Navigation = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Navbar>
      <NavMenu>
        <NavItem>
          <NavButton onClick={scrollToTop}></NavButton>
        </NavItem>
        <NavItem>
          <a href="#vorteile">Vorteile</a>
        </NavItem>
        <NavItem>
          <a href="#portofolio">Portofolio</a>
        </NavItem>
        <NavItem>
          <a href="#leistungen">Leistungen</a>
        </NavItem>
        <NavItem>
          <a href="#preise">Preise</a>
        </NavItem>
        <NavItem>
          <a href="#faq">FAQs</a>
        </NavItem>
      </NavMenu>
    </Navbar>
  );
};

export default Navigation;
