import React from 'react';
import styled from 'styled-components';
import footerShape from '../style/assets/shapes/footer-shape.png';
import grayLogo from '../style/assets/logo/gray-logo.png';

const FooterContainer = styled.footer`
  background-color: #3A3939;
  color: #DBDCCD;
  padding: 5rem 1.25rem;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ShapeBottomRight = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 18.5rem;
  height: 25rem;
  background-image: url(${footerShape});
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 1;

  @media (max-width: 768px) {
    opacity: 0.5;
  }
`;

const Title = styled.h2`
  font-family: 'Poppins', Arial, sans-serif;
  font-weight: bold;
  font-size: 5.2rem;
  margin-bottom: 1.5rem;
  line-height: 4.2rem;
  color: #DBDCCD;

  @media (max-width: 768px) {
    font-size: 2rem;
    line-height: 2rem;
  }
`;

const Subtitle = styled.p`
  font-size: 1.25rem;
  font-family: 'Roc Grotesk', Arial, sans-serif;
  margin-bottom: 40px;

  span {
    font-family: 'Poppins', Arial, sans-serif;
  }
`;

const LargeButton = styled.a`
  margin-top: 9rem;
  padding: 1rem 2rem;
  font-family: 'Poppins', Arial, sans-serif;
  font-weight: bold;
  font-size: 5.2rem;
  background-color: transparent;
  border: 1px solid #DBDCCD;
  border-radius: 0.25rem;
  cursor: pointer;
  color: #DBDCCD;
  width: 40rem;
  height: auto;
  -webkit-box-shadow: 5px 1px 20px -5px rgba(0,0,0,0.5); 
  box-shadow: 5px 1px 20px -5px rgba(0,0,0,0.5);
  text-decoration: none;

  &:hover {
    background-color: black;
  }

  @media (max-width: 768px) {
    margin-top: 1rem;
    font-size: 2rem;
    width: auto;
  }
`;

const MenuWrapper = styled.div`
  margin-top: 9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

  @media (max-width: 768px) {
    margin-top: 1rem;
  }
`;

const Menu = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.5rem;
  width: 50rem;

  @media (max-width: 768px) {
    flex-direction: column;
    width: auto;
    margin-bottom: 0;
  }
`;

const MenuItem = styled.a`
  cursor: pointer;
  text-decoration: none;
  font-size: 1.25rem;
  font-family: 'Roc Grotesk', Arial, sans-serif;
  color: #DBDCCD;

  &:hover {
    color: #e59437;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 5rem;

  @media (max-width: 768px) {
    margin: 0 auto;
  }
`;

const Logo = styled.img`
  max-width: 150px;
  height: auto;
`;

const LogoText = styled.p`
  font-family: 'Roc Grotesk', Arial, sans-serif;
  font-size: 0.5rem;
  text-align: left;
  color: #7D7E73;
`;

const SubMenu = styled.div`
  margin-top: 3rem;
  margin-bottom: 1.5rem;
  display: flex;
  width: 50rem;
  justify-content: space-between;

  p {
    font-family: 'Roc Grotesk', Arial, sans-serif;
    font-size: 0.75rem;

    a {
      text-decoration: none;
      color: #DBDCCD;
    }

    a:hover {
      color: #e59437;
    }
  }

  @media (max-width: 768px) {
    margin-top: 1rem;
    flex-direction: column;
    width: auto;
    z-index: 100;

    &:first-child {
      order: 3;
    }
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <Title>Wir sind bereit, bist du es auch?</Title>
      <Subtitle>Lasse uns die Art und Weise der Design<span>-</span>Beschaffung neu definieren<span>?</span></Subtitle>
      <LargeButton href="https://buy.stripe.com/fZeaHM53If72gA86op" target="_blank" rel="noreferrer">Los geht's!</LargeButton>
      <MenuWrapper>
        <Menu>
          <MenuItem href="#preise">Preise</MenuItem>
          <MenuItem href="https://billing.stripe.com/p/login/bIYeW4gqL5z93EQbII" target="_blank" rel="noreferrer">Kunden Login</MenuItem>
          <MenuItem href="mailto:hello@graflow.ch" target="_blank" rel="noreferrer">Kontakt</MenuItem>
        </Menu>
      </MenuWrapper>


      <SubMenu>
        <p><a href="https://laser-bolt-da2.notion.site/Allgemeine-Gesch-ftsbedingungen-cca23450016145efa3aa66cf648a43f5?pvs=4" target="_blank" rel="noreferrer">Allgemeine Geschäftsbedingungen</a></p>
        <LogoWrapper>
          <Logo src={grayLogo} alt="Logo" />
          <LogoText>Graflow is headquartered in<br />Bern, Switzerland.</LogoText>
        </LogoWrapper>
        <p><a href="https://laser-bolt-da2.notion.site/Datenschutzrichtlinie-189ea77b966046d180271f5898fb4c2f?pvs=4" target="_blank" rel="noreferrer">Datenschutzrichtlinie</a></p>
      </SubMenu>
      <ShapeBottomRight />
    </FooterContainer>
  );
};

export default Footer;
