import React from 'react';
import styled from 'styled-components';
import sectionBg from '../style/assets/shapes/section-bg.png';
import mobileMockup from '../style/assets/img/pitch-section-image-1.png';
import icon from '../style/assets/icons/pitch-icon.png';
import carMockup from '../style/assets/img/gulliver-car.png';
import businessCardMockup from '../style/assets/img/business-card.png';

const PitchContainer = styled.section`
  padding: 3rem;
  text-align: left;
  background-color: #F0CC39;
  position: relative;
  background-image: url(${sectionBg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top -50rem right;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    padding: 2rem;
    background-position: top right;

    #middle-row {
      flex-direction: column-reverse;
    }
  }
`;

const Title = styled.h2`
  font-family: 'Poppins', Arial, sans-serif;
  font-weight: bold;
  font-size: 3.2rem;
  line-height: 3.2rem;
  color: #382E2E;
  margin: 0;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 2rem;
    line-height: 2rem;
  }
`;

const Subtitle = styled.p`
  font-family: 'Poppins', Arial, sans-serif;
  font-weight: 600;
  font-size: 1.25rem;
  margin-bottom: 2.5rem;
  line-height: 1.2rem;
  color: #382E2E;
  text-align: center;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.25rem;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const Column = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    &:first-child {
      margin-bottom: 1.25rem;
    }
  }
`;

const Image = styled.img`
  max-width: 100%;
  height: auto;
  margin-bottom: 0.5rem;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  padding: 1rem;
  border: 1px solid #F0CC39;
  border-radius: 15px;

  @media (max-width: 768px) {
    width: 50%;
    padding: 1rem;
    border: 1px solid #F0CC39;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

const Icon = styled.img`
  margin-right: 10px;
  font-size: 1.5rem;
`;

const Text = styled.p`
  font-size: 1.7rem;
  color: #382E2E;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const PitchSection = () => {
  return (
    <PitchContainer id="portofolio">
      <Title>Ad - Design</Title>
      <Subtitle>und alles was ein Unternehmen braucht...</Subtitle>
      <ContentWrapper>
        <Column>
          <Image src={mobileMockup} alt="" />
        </Column>
        <Column>
          <TextWrapper>
            <Icon src={icon} />
            <Text>Kreative Exzellenz und maßgeschneiderte Lösungen für dein Unternehmen.</Text>
          </TextWrapper>
        </Column>
      </ContentWrapper>
      <ContentWrapper id="middle-row">
        <Column>
          <TextWrapper>
            <Icon src={icon} />
            <Text>Effektive Kommunikation durch beeindruckendes Design.</Text>
          </TextWrapper>
        </Column>
        <Column>
          <Image src={carMockup} alt="" />
        </Column>
      </ContentWrapper>
      <ContentWrapper>
        <Column>
          <Image src={businessCardMockup} alt="" />
        </Column>
        <Column>
          <TextWrapper>
            <Icon src={icon} />
            <Text>Graflow bringt dein Unternehmen visuell wie auch physisch auf eine neue Ebene.</Text>
          </TextWrapper>
        </Column>
      </ContentWrapper>
    </PitchContainer>
  );
};

export default PitchSection;
