import React, { useState } from 'react';
import styled from 'styled-components';
import logo from '../style/assets/logo/logo.svg';
import bgShape from '../style/assets/shapes/header-bg.png';

const HeaderWrapper = styled.header`
  background-color: #DBDCCD;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem 0 2rem 0;
  position: relative;

  @media (max-width: 768px) {
    justify-content: space-between;
    padding: 3rem 2rem 2rem 2rem;
  }
`;

const BackgroundShape = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  height: 80px;
  background-image: url(${bgShape});
  background-size: contain;
  background-repeat: no-repeat;

  @media (max-width: 768px) {
    display: none;
  }
`;

const Logo = styled.img`
  max-width: 15rem;
`;

const LogIn = styled.div`
  width: 8rem;
  height: 3rem;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LogInButton = styled.a`
  padding: 3rem 2rem;
  font-family: 'Roc Grotesk', Arial, sans-serif;
  font-size: 1rem;
  text-decoration: none;
  color: #707070;

  &:hover {
    color: #000;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const HamburgerIcon = styled.div`
  display: none;
  flex-direction: column;
  cursor: pointer;
  margin-right: 1rem;

  @media (max-width: 768px) {
    display: flex;
  }
`;

const Bar = styled.div`
  width: 25px;
  height: 3px;
  background-color: #000;
  margin: 3px 0;
`;

const MobileMenu = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 5px;
  padding: 1rem;
  z-index: 100;

  ul {
    list-style: none;

    li {
      a {
        text-decoration: none;
        color: #DBDCCD;
        font-family: 'Roc Grotesk', Arial, sans-serif;
        font-weight: bold;
        font-size: 2rem;
        line-height: 4rem;
      }

      a:active {
        color: #e59437;
      }
    }
  }

  @media (max-width: 768px) {
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  }
`;

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <HeaderWrapper>
      <BackgroundShape />
      <Logo src={logo} alt="Logo" />
      <LogIn>
        <LogInButton href="https://billing.stripe.com/p/login/bIYeW4gqL5z93EQbII" target="_blank" rel="noreferrer">Log in</LogInButton>
      </LogIn>
      <HamburgerIcon onClick={toggleMenu}>
        <Bar />
        <Bar />
        <Bar />
      </HamburgerIcon>
      <MobileMenu isOpen={isMenuOpen}>
        <ul>
          <li><a href="#vorteile">Vorteile</a></li>
          <li><a href="#portofolio">Portofolio</a></li>
          <li><a href="#leistungen">Leistungen</a></li>
          <li><a href="#preise">Preise</a></li>
          <li><a href="#faq">FAQs</a></li>
          <li><a href="https://billing.stripe.com/p/login/bIYeW4gqL5z93EQbII" target="_blank" rel="noreferrer">Log in</a></li>
        </ul>
      </MobileMenu>
    </HeaderWrapper>
  );
};

export default Header;
